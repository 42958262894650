import React from "react";
import { graphql } from "gatsby";

import SecretLayout from "../components/secret-layout/secret-layout";
import SlpLineChart from "../components/axie-farm/SlpLineChart";
import dansAquaImage from "../images/dans-aqua.png"

// just for template files we don't import {useStaticQuery}
// 1. graphql will run the query
// 2. $accountName will come from the context we setup when created the page
// 3. it will export as (props.data) in the page
export const data = graphql`
    query($accountName: String!) {
        allContentfulAxieFarmEntryClaimSlp(
            filter: { accountName: { eq: $accountName }},
            sort: { fields: claimedDate, order: ASC }
        ) {
            edges {
                node {
                    id
                    accountName
                    earnedSlp
                    claimedDate
                }
            }
        }          
    }
`;

    
// enum
const CurrencyType = {
    SLP: "SLP",
    USD: "USD",
    ETH: "ETH",
};

const UsdExchangeRate = {
    USD: 1, // USD/USD
    SLP: 10.836355004302542, // USD/SLP
    ETH: 0.00023661526594372814, // USD/ETH
};

const GetUsdExchangeRate = (currencyType) => {
    switch (currencyType) {
        case CurrencyType.SLP:
            return UsdExchangeRate.SLP;
        case CurrencyType.USD:
            return (
                UsdExchangeRate.USD
            );
        case CurrencyType.ETH:
            return (
                UsdExchangeRate.ETH
            );
        default:
            return (
                UsdExchangeRate.SLP                
                );
    }
}
   


const AxieAccountPage = props => {    
    
    var contentEntries = props.data.allContentfulAxieFarmEntryClaimSlp.edges
            .filter(slpEntry => slpEntry.node.accountName === props.pageContext.accountName)
            .map(slpEntry => ({
                earnedSlp: slpEntry.node.earnedSlp,
                claimedDate: slpEntry.node.claimedDate,
                accountName: slpEntry.node.accountName,
            }));

    var customDailyData = []; // custom data derived from the {contentEntries}.
    var dailyData = []; // daily data points.
    var monthlyData = []; // monthly data points.

    var previousDate = props.pageContext.createdDate;
    var totalSlpCount = 0;
    var tempDataPointSlpCount = 0;

    // loop through each content entry.
    for (let i = 0; i < contentEntries.length; i++) {
        // work out the average slp per day.
        const claimedDate = contentEntries[i].claimedDate;
        const daysBetween = CalculateDaysBetween(previousDate, claimedDate);
        const averageSlpPerDay =
            daysBetween === 0
                ? 0
                : contentEntries[i].earnedSlp / daysBetween;

        customDailyData.push({
            claimedDate: new Date(claimedDate).toLocaleDateString("en-uk"),
            daysBetween: daysBetween,
            averageSlpPerDay: averageSlpPerDay,
        });

        // split the {customDailyData} into daily data points.
        for (let i = 0; i < daysBetween; i++) {
            const date = AddDays(previousDate, i + 1);

            dailyData.push({
                slpGained: averageSlpPerDay,
                date: date.toLocaleDateString("en-uk"),
            });
        }

        // split the {customeDailyData} into monthly data points.
        for (let i = 0; i < daysBetween; i++) {
            const date = AddDays(previousDate, i + 1); // increment the date

            // if date is on the 1st, push the data before incrementing.
            if (date.getDate() === 1) {
                // decrement the date i.e. end of the previous month
                const dataPointDate = AddDays(date, -1).toLocaleDateString("en-uk");

                monthlyData.push({
                    slpGained: tempDataPointSlpCount,
                    date: dataPointDate,
                    totalSlpGained: totalSlpCount
                });
                                               
                tempDataPointSlpCount = 0; // reset the slp count.
            }
            // keep adding the daily slp earned until the end of the month.
            tempDataPointSlpCount += averageSlpPerDay;
            totalSlpCount += averageSlpPerDay;
        }

        previousDate = claimedDate;
    }

    // adds a number of days to a specified date then returns the new date.
    function AddDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    function CalculateDaysBetween(date1, date2) {
        if (new Date(date1).valueOf() === new Date(date2).valueOf()) {
            // return 0 days difference
            return 0;
        }

        var difference =
            new Date(date2).getTime() - new Date(date1).getTime();

        return Math.ceil(difference / (1000 * 3600 * 24));
    }

    const dailyDatasets = [
        {
            data: dailyData.map(data => data.slpGained),
            label: "Earned SLP (average)",
            borderColor: "pink",
            backgroundColor: "rgba(255, 0, 0, 0.5)",
            fill: false, //fill space below chart
        },
    ]

    const monthlyDatasets = [{
            data: monthlyData.map(data =>
                Math.round(data.slpGained)
            ),
            label: "Earned SLP (average)",
            borderColor: "pink",
            backgroundColor: "rgba(255, 0, 0, 0.5)",
            fill: false, //fill space below chart
        }
    ];

    const accumulativeMonthlyDatasets = [{
        data: monthlyData.map(data =>
            Math.round(data.totalSlpGained)
        ),
        label: "Total Earnings",
        borderColor: "pink", // TODO: add contentful config for this
        backgroundColor: "rgba(255, 0, 0, 0.5)", // TODO: add contentful config for this
        fill: true, //fill space below chart
    },
    {
        data: monthlyData.map(data => {
            //map same number of data points as main data
            return props.pageContext.totalCost * GetUsdExchangeRate(CurrencyType.SLP) // USD/SLP
        }), 
        label: "Breakeven",
        borderColor: "rgba(14, 255, 94, 1)",
        backgroundColor: "rgba(14, 255, 94, 0.5)",
        fill: false, //fill space below chart
        pointStyle: "line",
        useCurrentExchangeRatesOnly: true
    }];

    return (
        <SecretLayout title={props.pageContext.accountName} navIndex={1} headerImage={dansAquaImage}>      
            <SlpLineChart 
                title={`Total Earnings Report (${props.pageContext.accountName})`}
                labels={monthlyData.map(data => data.date)}
                datasets={accumulativeMonthlyDatasets}
            />

            <SlpLineChart 
                title={`Monthly Earnings (${props.pageContext.accountName})`}
                labels={monthlyData.map(data => data.date)}
                datasets={monthlyDatasets}
            />

            <SlpLineChart 
                title={`Daily Earnings (${props.pageContext.accountName})`}
                labels={dailyData.map(data => data.date)}
                datasets={dailyDatasets}
            />
        </SecretLayout>
    );
};

export default AxieAccountPage;

              
 
